var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('span',{staticClass:"text-h6"},[_vm._v(" Nombre ")])]),_c('v-text-field',{attrs:{"outlined":"","hide-details":"","value":_vm.editedItem.name},on:{"change":function (e) {this$1.$emit('update:editedItem', Object.assign({}, _vm.editedItem,
            {name: e}))}}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('span',{staticClass:"text-h6"},[_vm._v(" Descripcion ")])]),_c('v-textarea',{attrs:{"outlined":"","hide-details":"","rows":4,"value":_vm.editedItem.description,"name":"input-7-4"},on:{"change":function (e) {this$1.$emit('update:editedItem', Object.assign({}, _vm.editedItem,
            {description: e}))}}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('span',{staticClass:"text-h6"},[_vm._v(" Descripcion resumida ")])]),_c('v-textarea',{attrs:{"outlined":"","rows":2,"hide-details":"","value":_vm.editedItem.shortDescription,"name":"input-7-4"},on:{"change":function (e) {this$1.$emit('update:editedItem', Object.assign({}, _vm.editedItem,
            {shortDescription: e}))}}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('span',{staticClass:"text-h6"},[_vm._v(" Categorias ")])]),_vm._l((_vm.systemModules),function(systemModule){return _c('v-checkbox',{key:("module-" + (systemModule.id)),attrs:{"label":systemModule.name,"color":"primary","hide-details":""},model:{value:(systemModule.checked),callback:function ($$v) {_vm.$set(systemModule, "checked", $$v)},expression:"systemModule.checked"}})})],2),_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('span',{staticClass:"text-h6"},[_vm._v(" Modalidades ")])]),_vm._l((_vm.careersModalities),function(item){return _c('v-checkbox',{key:("module-" + (item.id)),attrs:{"label":item.name,"color":"primary","hide-details":""},model:{value:(item.checked),callback:function ($$v) {_vm.$set(item, "checked", $$v)},expression:"item.checked"}})})],2),(!_vm.editedItem.id)?_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('span',{staticClass:"text-h6"},[_vm._v(" Imagenes ")])]),_c('v-file-input',{attrs:{"value":_vm.editedItem.files,"small-chips":"","counter":"","show-size":"","multiple":""},on:{"change":function (e) {
            this$1.$emit('update:editedItem', Object.assign({}, _vm.editedItem,
              {files: e,
              images: e}))
          }}})],1):_vm._e(),(_vm.editedItem.images)?_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('span',{staticClass:"text-h6"},[_vm._v(" Caratula de Curso ")])]),_c('v-radio-group',{model:{value:(_vm.editedItem.preview),callback:function ($$v) {_vm.$set(_vm.editedItem, "preview", $$v)},expression:"editedItem.preview"}},_vm._l((_vm.editedItem.images),function(image,index){return _c('v-radio',{key:("file-" + image),attrs:{"label":image.name,"value":index,"color":"primary","hide-details":""}})}),1)],1):_vm._e(),(_vm.editedItem.images)?_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('span',{staticClass:"text-h6"},[_vm._v(" Caratula de Pagina de Curso ")])]),_c('v-radio-group',{model:{value:(_vm.editedItem.cover),callback:function ($$v) {_vm.$set(_vm.editedItem, "cover", $$v)},expression:"editedItem.cover"}},_vm._l((_vm.editedItem.images),function(image,index){return _c('v-radio',{key:("file-" + image),attrs:{"label":image.name,"value":index,"color":"primary","hide-details":""}})}),1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }