<template>
  <v-card-text>
    <v-container>
      <v-row>
        <v-col
          cols="12"
        >
          <div>
            <span
              class="text-h6"
            >
              Nombre
            </span>
          </div>
          <v-text-field
            outlined
            hide-details
            @change="(e) => {this.$emit('update:editedItem', {
              ...editedItem,
              name: e
            })}"
            :value="editedItem.name"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
        >
          <div>
            <span
              class="text-h6"
            >
              Curso
            </span>
          </div>
          <v-combobox
            @change="(e) => {this.$emit('update:editedItem', {
              ...editedItem,
              course: e
            })}"
            :value="editedItem.course"
            :items="courses"
            clearable
            outlined
          ></v-combobox>
        </v-col>
        <v-col
          cols="12"
        >
          <div>
            <span
              class="text-h6"
            >
              Fecha
            </span>
          </div>
          <div class="full-width d-flex justify-center">
            <v-date-picker
              v-model="editedItem.dates"
              range
              no-title
            ></v-date-picker>
          </div>
        </v-col>
        <v-col
          cols="12"
        >
          <div>
            <span
              class="text-h6"
            >
              Horario de cursado
            </span>
          </div>
          <v-col style="width: 350px; flex: 0 1 auto;">
            <v-dialog
              ref="dialog"
              v-model="modal1"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="editedItem.timeStart"
                  label="Hora de inicio"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="modal1"
                v-model="editedItem.timeStart"
                full-width
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="modal1 = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="() => {
                    modal1 = false
                  }"
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-col>
          <v-col style="width: 350px; flex: 0 1 auto;">
            <v-dialog
              ref="dialog"
              v-model="modal2"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="editedItem.timeEnd"
                  label="Hora de fin"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="modal2"
                v-model="editedItem.timeEnd"
                full-width
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="modal2 = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="() => {
                    modal2 = false
                  }"
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-col>
        </v-col>
      </v-row>
    </v-container>
    </v-card-text>
</template>

<script>
export default {
  props: {
    systemModules: {
      type: Array
    },
    editedItem: {
      type: String
    },
    careersModalities: {
      type: Array
    },
    courses: {
      type: Array
    }
  },

  data: () => ({
    modal1: false,
    modal2: false,
    start: null,
    end: null,
  }),

  methods: {
    testa (e) {
      console.log('aaaaa')
      console.log(e, '<<<<<<<')
    }
  }
}
</script>

<style>

</style>