var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('span',{staticClass:"text-h6"},[_vm._v(" Nombre ")])]),_c('v-text-field',{attrs:{"outlined":"","hide-details":"","value":_vm.editedItem.name},on:{"change":function (e) {this$1.$emit('update:editedItem', Object.assign({}, _vm.editedItem,
            {name: e}))}}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('span',{staticClass:"text-h6"},[_vm._v(" Curso ")])]),_c('v-combobox',{attrs:{"value":_vm.editedItem.course,"items":_vm.courses,"clearable":"","outlined":""},on:{"change":function (e) {this$1.$emit('update:editedItem', Object.assign({}, _vm.editedItem,
            {course: e}))}}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('span',{staticClass:"text-h6"},[_vm._v(" Fecha ")])]),_c('div',{staticClass:"full-width d-flex justify-center"},[_c('v-date-picker',{attrs:{"range":"","no-title":""},model:{value:(_vm.editedItem.dates),callback:function ($$v) {_vm.$set(_vm.editedItem, "dates", $$v)},expression:"editedItem.dates"}})],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('span',{staticClass:"text-h6"},[_vm._v(" Horario de cursado ")])]),_c('v-col',{staticStyle:{"width":"350px","flex":"0 1 auto"}},[_c('v-dialog',{ref:"dialog",attrs:{"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Hora de inicio","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.editedItem.timeStart),callback:function ($$v) {_vm.$set(_vm.editedItem, "timeStart", $$v)},expression:"editedItem.timeStart"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal1),callback:function ($$v) {_vm.modal1=$$v},expression:"modal1"}},[(_vm.modal1)?_c('v-time-picker',{attrs:{"full-width":""},model:{value:(_vm.editedItem.timeStart),callback:function ($$v) {_vm.$set(_vm.editedItem, "timeStart", $$v)},expression:"editedItem.timeStart"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal1 = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function () {
                  _vm.modal1 = false
                }}},[_vm._v(" OK ")])],1):_vm._e()],1)],1),_c('v-col',{staticStyle:{"width":"350px","flex":"0 1 auto"}},[_c('v-dialog',{ref:"dialog",attrs:{"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Hora de fin","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.editedItem.timeEnd),callback:function ($$v) {_vm.$set(_vm.editedItem, "timeEnd", $$v)},expression:"editedItem.timeEnd"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal2),callback:function ($$v) {_vm.modal2=$$v},expression:"modal2"}},[(_vm.modal2)?_c('v-time-picker',{attrs:{"full-width":""},model:{value:(_vm.editedItem.timeEnd),callback:function ($$v) {_vm.$set(_vm.editedItem, "timeEnd", $$v)},expression:"editedItem.timeEnd"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal2 = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function () {
                  _vm.modal2 = false
                }}},[_vm._v(" OK ")])],1):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }