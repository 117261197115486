<template>
  <v-card-text>
    <v-container>
      <v-row>
        <v-col
          cols="12"
        >
          <div>
            <span
              class="text-h6"
            >
              Nombre
            </span>
          </div>
          <v-text-field
            outlined
            hide-details
            @change="(e) => {this.$emit('update:editedItem', {
              ...editedItem,
              name: e
            })}"
            :value="editedItem.name"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
        >
          <div>
            <span
              class="text-h6"
            >
              Apellido
            </span>
          </div>
          <v-text-field
            outlined
            hide-details
            @change="(e) => {this.$emit('update:editedItem', {
              ...editedItem,
              lastName: e
            })}"
            :value="editedItem.lastName"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
        >
          <div>
            <span
              class="text-h6"
            >
              Email
            </span>
          </div>
          <v-text-field
            outlined
            hide-details
            @change="(e) => {this.$emit('update:editedItem', {
              ...editedItem,
              email: e
            })}"
            :value="editedItem.email"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
        >
          <div>
            <span
              class="text-h6"
            >
              Rol
            </span>
          </div>
          <v-checkbox
            v-for="role in roles"
            :key="`role-${role.id}`"
            v-model="role.checked"
            :label="role.name"
            color="primary"
            hide-details
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-container>
    </v-card-text>
</template>

<script>
export default {
  props: {
    systemModules: {
      type: Array
    },
    editedItem: {
      type: String
    },
    roles: {
      type: Array
    }
  },

  data: () => ({
    modal1: false,
    modal2: false,
    start: null,
    end: null,
  }),

  methods: {
    testa (e) {
      console.log('aaaaa')
      console.log(e, '<<<<<<<')
    }
  }
}
</script>

<style>

</style>