<template>
  <v-card-text>
    <v-container>
      <v-row>
        <v-col
          cols="12"
        >
          <div>
            <span
              class="text-h6"
            >
              Nombre
            </span>
          </div>
          <v-text-field
            outlined
            hide-details
            @change="(e) => {this.$emit('update:editedItem', {
              ...editedItem,
              name: e
            })}"
            :value="editedItem.name"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
        >
          <div>
            <span
              class="text-h6"
            >
              Descripcion
            </span>
          </div>
          <v-textarea
            outlined
            hide-details
            :rows="4"
            @change="(e) => {this.$emit('update:editedItem', {
              ...editedItem,
              description: e
            })}"
            :value="editedItem.description"
            name="input-7-4"
          ></v-textarea>
        </v-col>
        <v-col
          cols="12"
        >
          <div>
            <span
              class="text-h6"
            >
              Descripcion resumida
            </span>
          </div>
          <v-textarea
            outlined
            :rows="2"
            hide-details
            @change="(e) => {this.$emit('update:editedItem', {
              ...editedItem,
              shortDescription: e
            })}"
            :value="editedItem.shortDescription"
            name="input-7-4"
          ></v-textarea>
        </v-col>
        <v-col
          cols="12"
        >
          <div>
            <span
              class="text-h6"
            >
              Categorias
            </span>
          </div>
          <v-checkbox
            v-for="systemModule in systemModules"
            :key="`module-${systemModule.id}`"
            v-model="systemModule.checked"
            :label="systemModule.name"
            color="primary"
            hide-details
          ></v-checkbox>
        </v-col>
        <v-col
          cols="12"
        >
          <div>
            <span
              class="text-h6"
            >
              Modalidades
            </span>
          </div>
          <v-checkbox
            v-for="item in careersModalities"
            :key="`module-${item.id}`"
            v-model="item.checked"
            :label="item.name"
            color="primary"
            hide-details
          ></v-checkbox>
        </v-col>
        <v-col
          v-if="!editedItem.id"
          cols="12"
        >
          <div>
            <span
              class="text-h6"
            >
              Imagenes
            </span>
          </div>
          <v-file-input
            @change="(e) => {
              this.$emit('update:editedItem', {
                ...editedItem,
                files: e,
                images: e
              })
            }"
            :value="editedItem.files"
            small-chips
            counter
            show-size
            multiple
          ></v-file-input>
        </v-col>
        <v-col
          v-if="editedItem.images"
          cols="12"
        >
          <div>
            <span
              class="text-h6"
            >
              Caratula de Curso
            </span>
          </div>
          <v-radio-group v-model="editedItem.preview">
            <v-radio
              v-for="(image, index) in editedItem.images"
              :key="`file-${image}`"
              :label="image.name"
              :value="index"
              color="primary"
              hide-details
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col
          v-if="editedItem.images"
          cols="12"
        >
          <div>
            <span
              class="text-h6"
            >
              Caratula de Pagina de Curso
            </span>
          </div>
          <v-radio-group v-model="editedItem.cover">
            <v-radio
              v-for="(image, index) in editedItem.images"
              :key="`file-${image}`"
              :label="image.name"
              :value="index"
              color="primary"
              hide-details
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
    </v-container>
    </v-card-text>
</template>

<script>
export default {
  props: {
    systemModules: {
      type: Array
    },
    editedItem: {
      type: String
    },
    careersModalities: {
      type: Array
    }
  }
}
</script>

<style>

</style>