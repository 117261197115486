<template>
  <div class="pa-10">
    <span class="text-h4">
      {{$router.currentRoute.params.id}}
    </span>
    <br/>
    <br/>
    <v-data-table
      :headers="headers"
      :items="items"
      sort-by="calories"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                @click="handleNewItemClick"
                v-on="on"
              >
                Crear
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <careers-form
                v-if="$router.currentRoute.params.id === 'careers'"
                :systemModules="systemModules"
                :careersModalities="careersModalities"
                :editedItem.sync="editedItem"
              />

              <periods-form
                v-if="$router.currentRoute.params.id === 'periods'"
                :editedItem.sync="editedItem"
                :courses="formattedCourses"
              />
              
              <members-form
                v-if="$router.currentRoute.params.id === 'members'"
                :editedItem.sync="editedItem"
                :roles="formattedRoles"
              />

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="title">
                Borrar
                <strong class="mx-2 orange--text text--darken-3">{{editedItem.name}}</strong> ?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn disabled v-if="isDeleting" color="blue darken-1" text @click="deleteItemConfirm">
                  <v-progress-circular
                    indeterminate
                    color="amber"
                  ></v-progress-circular>
                </v-btn>
                <v-btn v-else color="blue darken-1" text @click="deleteItemConfirm">
                  OK
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initialize"
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import { db, storage } from '@/firebaseSDK'
  import careersForm from '@/components/CareersForm'
  import periodsForm from '@/components/PeriodsForm'
  import membersForm from '@/components/MembersForm'
  import moment from 'moment';

  export default {
    components: {
      careersForm,
      periodsForm,
      membersForm
    },

    data: () => ({
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [],
      editedIndex: -1,
      editedItem: {
      },
      defaultItem: {
        name: '',
      },
      systemModules: [],
      careersModalities: [],
      roles: [],
      courses: [],
      isDeleting: false,
      entities: {
        careers: {
          keysToAvoid: ['images', 'preview', 'videos', 'id', 'content', 'cover'],
        },
        members: {
          keysToAvoid: ['images', 'id', 'about', 'experience', 'education', 'certifications', 'socialNetworks'],
        },
      }
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Creacion' : 'Edicion'
      },

      formattedCourses () {
        return this.courses.map((course) => ({
          text: course.name,
          value: course.id
        }))
      },

      formattedRoles () {
        return this.roles
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    async created () {
      this.initialize()
    },

    methods: {
      async initialize () {
        try {
          let docsRef = await db.collection(this.$router.currentRoute.params.id).get()
          let fetchedDocs = docsRef.docs.map((doc) => ({...doc.data(), id: doc.id}))
          for (const key in fetchedDocs[0]) {
            if (Object.hasOwnProperty.call(fetchedDocs[0], key)) {
              if (!this.entities[this.$router.currentRoute.params.id].keysToAvoid.includes(key))
              this.headers.unshift({
                text: key,
                value: key
              })
            }
          }
          this.items = fetchedDocs.map((doc) => ({...doc}))
        } catch (err) {
          return err
        }
      },
      
      handleChangeImages (files) {
        this.editedItem.images = files.map((file) => `gs://tod3d-e5390.appspot.com/images/careers/${this.editedItem.name}/${file.name}`)
      },

      async handleNewItemClick () {
        this.editedItem = {}
        if (this.$router.currentRoute.params.id === 'careers') {
          if (!this.systemModules.length) {
            try {
              let docsRef = await db.collection('careers-categories').get()
              let docsRefModalities = await db.collection('careers-modalities').get()
              let docsRefCourses = await db.collection('careers').get()
              let fetchedDocs = docsRef.docs.map((doc) => ({...doc.data(), id: doc.id, checked: false}))
              let fetchedDocsModalities = docsRefModalities.docs.map((doc) => ({...doc.data(), id: doc.id, checked: false}))
              let fetchedDocsCourses = docsRefCourses.docs.map((doc) => ({...doc.data(), id: doc.id, checked: false}))
              this.systemModules = fetchedDocs
              this.careersModalities = fetchedDocsModalities
              this.courses = fetchedDocsCourses
            } catch (err) {
              return err
            }
          }
        }
        if (this.$router.currentRoute.params.id === 'members') {
          try {
              let docsRef = await db.collection('roles').get()
              let fetchedDocs = docsRef.docs.map((doc) => ({...doc.data(), id: doc.id, checked: false}))
              this.roles = fetchedDocs
            } catch (err) {
              return err
            }
        }
      },

      async editItem (item) {
        this.editedIndex = this.items.indexOf(item)
        this.editedItem = {...item}
        if (!this.systemModules.length) {
          try {
            let docsRef = await db.collection('careers-categories').get()
            let docsRefModalities = await db.collection('careers-modalities').get()
            let fetchedDocs = docsRef.docs.map((doc) => ({...doc.data(), id: doc.id, checked: false}))
            let fetchedDocsModalities = docsRefModalities.docs.map((doc) => ({...doc.data(), id: doc.id, checked: false}))
            this.systemModules = fetchedDocs
            this.careersModalities = fetchedDocsModalities
          } catch (err) {
            return err
          }
        }
        this.systemModules.forEach((systemModule) => {
          let selectedItem = this.editedItem.categories.find((itemModule) => itemModule === systemModule.id)
          if (selectedItem) {
            systemModule.checked = true
          } else {
            systemModule.checked = false
          }
        })
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.items.indexOf(item)
        this.editedItem = {...item}
        this.dialogDelete = true
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = {...this.defaultItem}
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = {...this.defaultItem}
          this.editedIndex = -1
        })
      },

      async saveMember () {
        try {
          const objectToSend = {
            name: this.editedItem.name,
            lastName: this.editedItem.lastName,
            email: this.editedItem.email,
            roles: this.roles.filter((role) => role.checked).map((role) => role.name)
          }
          const createdMember = await db.collection("members").add(objectToSend)
          console.log(createdMember, '<')
          this.items.push({...objectToSend})
        } catch (err) {
          console.log(err, 'err')
        }
      },

      async savePeriod () {
        try {
          db.collection("periods").add({
            name: this.editedItem.name,
            courseId: this.editedItem.course.value,
            dateStart: moment(this.editedItem.dates[0], 'YYYY-MM-DD').unix(),
            dateEnd: moment(this.editedItem.dates[1], 'YYYY-MM-DD').unix(),
            timeStart: this.editedItem.timeStart,
            timeEnd: this.editedItem.timeEnd,
          })
          this.items.push({...this.editedItem})
        } catch (err) {
          console.log(err, 'err')
        }
      },

      async saveCareer () {
        if (!this.editedItem.id) {
          try {
            let storageRef = storage.ref()
            let imagesReferences = []
            this.editedItem.files.forEach((file) => {
              imagesReferences.push({
                file,
                ref: storageRef.child(`images/careers/${this.editedItem.name}/${file.name}`),
              })
            })
            let calls = []
            imagesReferences.forEach((ref) => {
              calls.push(ref.ref.put(ref.file))
            })
            let responses = await Promise.allSettled(calls)
            let modulesToInclude = this.systemModules.filter((systemModule) => systemModule.checked).map((systemModule) => systemModule.id)
            let modalitiesToInclude = this.careersModalities.filter((modality) => modality.checked).map((modality) => modality.id)
            console.log({
              name: this.editedItem.name,
              description: this.editedItem.description,
              shortDescription: this.editedItem.shortDescription,
              modalities: modalitiesToInclude,
              images: responses.filter((response) => response.status === 'fulfilled').map((response) => `gs://tod3d-e5390.appspot.com/${response.value.ref.fullPath}`),
              videos: [],
              categories: modulesToInclude,
              preview: this.editedItem.preview,
              cover: this.editedItem.cover,
            })
            db.collection("careers").add({
              name: this.editedItem.name,
              description: this.editedItem.description,
              shortDescription: this.editedItem.shortDescription,
              modalities: modalitiesToInclude,
              images: responses.filter((response) => response.status === 'fulfilled').map((response) => `gs://tod3d-e5390.appspot.com/${response.value.ref.fullPath}`),
              videos: [],
              categories: modulesToInclude,
              preview: (responses[this.editedItem.preview].status === 'fulfilled') ? `gs://tod3d-e5390.appspot.com/${responses[this.editedItem.preview].value.ref.fullPath}` : '',
              cover: (responses[this.editedItem.cover].status === 'fulfilled') ? `gs://tod3d-e5390.appspot.com/${responses[this.editedItem.cover].value.ref.fullPath}` : ''
            })
            this.items.push({...this.editedItem, categories: modulesToInclude})
            // if (this.editedIndex > -1) {
          } catch (err) {
            console.log(err, 'err')
          }
        } else {
          try {
            let careerRef = db.collection("careers").doc(this.editedItem.id)
            let modulesToInclude = this.systemModules.filter((systemModule) => systemModule.checked).map((systemModule) => systemModule.id)
            let objectToSend = {
              name: this.editedItem.name,
              description: this.editedItem.description,
              categories: modulesToInclude,
              preview: this.editedItem.preview
            }
            careerRef.update(objectToSend)
            Object.assign(this.items[this.editedIndex], {...this.editedItem, categories: modulesToInclude})
          } catch (err) {
            console.log(err, 'err')
          }
        }
      },

      async deleteCareer () {
        try {
          this.isDeleting = true
          db.collection("careers").doc(this.editedItem.id).delete()
          let storageRef = storage.ref()
          this.editedItem.images.forEach((image) => {
            let filesRef = storageRef.child(image.slice(29));
            filesRef.delete()
          })
          this.items.splice(this.editedIndex, 1)
          this.closeDelete()
        } catch (err) {
          console.log(err)
        } finally {
          this.isDeleting = false
        }
      },

      async deleteMember () {
        try {
          this.isDeleting = true
          db.collection("members").doc(this.editedItem.id).delete()
          this.items.splice(this.editedIndex, 1)
          this.closeDelete()
        } catch (err) {
          console.log(err)
        } finally {
          this.isDeleting = false
        }
      },

      async save () {
        switch (this.$router.currentRoute.params.id) {
          case "careers":
            this.saveCareer()
            break;

          case "members":
            this.saveMember()
            break;

          default:
            break;
        }
        this.close()
      },

      deleteItemConfirm () {
        switch (this.$router.currentRoute.params.id) {
          case "careers":
            this.deleteCareer()
            break;

          case "members":
            this.deleteMember()
            break;

          default:
            break;
        }
      }

    },
  }
</script>

<style>

</style>